import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Container from 'components/Container';
import Layout from 'components/Layout';
import HoldRetryCard from 'delivery/execution/HoldRetryCard';
import React, { useState } from 'react';
import { ReactQueryConfigProvider, useMutation, useQuery } from 'react-query';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useGState } from 'state/store';
import { default as fetch, default as fetchPromise } from 'utils/fetch';

const useStyles = makeStyles(() => ({
  save: {
    marginTop: 20,
    background: '#4caf4f',
    color: 'white',
    '&:hover': {
      background: '#4caf4f',
    },
  },
  select: {
    width: 'inherit',
  },
}));

function HoldRetry() {
  const classes = useStyles();
  const animatedComponents = makeAnimated();

  const [holdRetryCards, setHoldRetryCards] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [distributorsSelected, setDistributorsSelected] = useState([]);

  const [stateChanges, setStateChanges] = useState([]);
  const [finalStateChanges, setFinalStateChanges] = useState([]);
  const [error, setError] = useState(false);

  const { branch } = useGState((s) => ({
    branch: s.branch,
  }));

  useQuery(
    ['holdRetry', { branch }],
    (_, { branch }) => fetchPromise(`v3/delivery/panel/return?nodeId=${branch.id}`),
    {
      onSuccess: (data) => {
        setHoldRetryCards(data);
        setDistributors(
          data
            .filter((item) => item.distributor)
            .map((item) => item.distributor)
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((distributor) => ({ value: distributor, label: distributor }))
        );
        setStateChanges(data.map((item) => ({ invoiceId: item.id, toReturnStatus: item.state })));
      },
    }
  );

  const [saveMultiInvoices] = useMutation(
    (finalStateChanges) =>
      fetch({
        method: 'POST',
        url: `/v3/delivery/panel/multi-return?nodeId=${branch.id}`,
        data: finalStateChanges,
      }),
    {
      onSuccess: () => {
        const final = holdRetryCards.filter((list1) =>
          finalStateChanges.some(
            (list2) => list1.id == list2.invoiceId && (list1.state = list2.toReturnStatus)
          )
        );

        const finalStates = holdRetryCards.filter((o1) =>
          final.some((o2) => (o1.id !== o2.id ? o1 : o2))
        );

        setHoldRetryCards(finalStates);
        setFinalStateChanges([]);
        setError(false);
      },
      onError: () => {
        setError(true);
        setFinalStateChanges([]);
      },
    }
  );

  const saveChanges = () => {
    finalStateChanges.length != 0 && saveMultiInvoices(finalStateChanges);
  };

  const onStateChange = (changedState, id) => {
    let newState = stateChanges.find((item) => item.invoiceId == id);
    newState.toReturnStatus = changedState;
    setStateChanges(stateChanges.filter((item) => (item.invoiceId == id ? newState : item)));
    setFinalStateChanges(
      stateChanges.filter((o1) =>
        holdRetryCards.some((o2) => o1.invoiceId == o2.id && o1.toReturnStatus != o2.state)
      )
    );
  };

  const removeError = () => {
    setError(false);
  };

  const styles = {
    multiValueRemove: (styles) => ({
      ...styles,
      ':hover': {
        backgroundColor: '#F88379',
        color: '#C41E3A',
      },
    }),
  };

  const handleChange = (e) => {
    setDistributorsSelected(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  return (
    <Container>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={4} style={{ marginTop: 20 }}>
          {distributors && distributors.length > 0 && (
            <Select
              options={distributors}
              placeholder="Distributor"
              isMulti
              components={animatedComponents}
              closeMenuOnSelect={false}
              className={classes.select}
              styles={styles}
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item>
          {holdRetryCards && (
            <Button
              variant="contained"
              className={classes.save}
              onClick={saveChanges}
              disabled={finalStateChanges.length == 0 ? true : false}
            >
              Save
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} align="stretch">
        {holdRetryCards
          .filter((card) =>
            distributorsSelected.length === 0
              ? card
              : distributorsSelected.includes(card.distributor)
          )
          .map((data) => (
            <Grid item xs={12} md={6} lg={4} key={data.id}>
              <HoldRetryCard
                data={data}
                barnchId={branch.id}
                onStateChange={onStateChange}
                error={error}
                removeError={removeError}
              />
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}

export default function HoldRetryContainer(props) {
  return (
    <Layout {...props} hideDateFilter>
      <ReactQueryConfigProvider
        config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
      >
        <HoldRetry></HoldRetry>
      </ReactQueryConfigProvider>
    </Layout>
  );
}
