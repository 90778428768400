import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { ga, useGState } from 'state/store';
import Select from 'react-select';
import { formatDateHumanReadable } from 'utils/utils';

const useStyles = makeStyles(() => ({
  card: {
    background: 'white',
    boxShadow: '0 2px 5px 0 rgba(139, 150, 164, 0.16)',
    border: 'solid 1px #eceef2',
    borderRadius: '8px',
    marginTop: '1rem',
    padding: '1rem',
  },
  retailerCode: {
    fontSize: '1.125rem',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  salesmanCode: {
    fontSize: '0.875rem',
    color: '#8d99b2',
  },
  type: {
    fontSize: '0.75rem',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: '#1c7fba',
  },
  textColor: {
    color: '#384561',
  },
  select: {
    width: '10.688rem',
  },
}));

export default function HoldRetryCard({ data, onStateChange, error, removeError }) {
  const classes = useStyles();

  const { retailer, salesman, value, createdAt, type, state, id, code, distributor } = data;
  const settings = useGState((s) => s[ga.CLIENT_PREFERENCE]);
  const returnTerminology = settings.returnTerminology;
  const dropdownOptions = [
    {
      value: 'HOLD',
      label: returnTerminology?.HOLD || 'HOLD',
      color: '#f0a63b',
      width: '6.688rem',
    },
    {
      value: 'RETRY',
      label: returnTerminology?.RETRY || 'RETRY',
      color: '#61d395',
      width: '6.688rem',
    },
    { value: 'SRN', label: returnTerminology?.SRN || 'SRN', color: '#eb4d6e', width: '6.688rem' },
    {
      value: 'DELIVERED_OFFLINE',
      label: 'DELIVERED OFFLINE',
      color: '#07a951',
      width: '10.563rem',
    },
  ];

  const styles = {
    option: (provided, state) => ({
      ...provided,
      color: 'white',
      background: state.data.color,
      width: '10.688rem',
      height: '2.313rem',
    }),
    control: (provided) => ({
      ...provided,
      background:
        reason === 'HOLD'
          ? '#f0a63b'
          : reason === 'RETRY'
          ? '#61d395'
          : reason === 'SRN'
          ? '#eb4d6e'
          : '#07a951',
      color: 'white',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white',
      background: state.data.color,
      height: '2.313rem',
      paddingTop: '0.5rem',
    }),
  };

  const [reason, setReason] = useState(state);

  const changeHoldRetry = (e) => {
    setReason(e.value);
    onStateChange(e.value, id);
  };

  useEffect(() => {
    if (error) setReason(state);
    removeError();
  }, [error, state, removeError]);

  return (
    <div className="bg-white rounded-xl p-4 my-4">
      <Box m={1} className="flex justify-between">
        <div>
          <Typography className={`${classes.retailerCode} ${classes.textColor}`}>{code}</Typography>
          <p className={`${classes.salesmanCode} my-1`}>{retailer?.code}</p>
        </div>
        <Box className="flex flex-col">
          <Typography className={classes.salesmanCode}>Marked on</Typography>
          <Typography className={classes.textColor}>
            {formatDateHumanReadable(createdAt)}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.textColor}>{retailer.name}</Box>
      <Box className="flex justify-between">
        <Box>
          <Typography>
            <span className={classes.salesmanCode}>Salesman code:</span>{' '}
            <span className={classes.textColor}>{salesman?.code || '--'}</span>
          </Typography>
          {distributor && (
            <Typography>
              <span className={classes.salesmanCode}>Distributor:</span>{' '}
              <span className={classes.textColor}>{distributor}</span>
            </Typography>
          )}
        </Box>
        <Typography className={classes.type}>{type}</Typography>
      </Box>
      <Box className="flex justify-between">
        <Typography className={classes.textColor}>₹ {value}</Typography>
        <Select
          options={dropdownOptions}
          onChange={changeHoldRetry}
          value={dropdownOptions.filter(function (option) {
            return option.value === reason;
          })}
          className={classes.select}
          styles={styles}
        />
      </Box>
    </div>
  );
}
